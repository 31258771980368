import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Header from "./components/header/Header";
import MobileMenu from "./components/mobileMenu/MobileMenu";
import { useStore } from "@nanostores/react";
import {
    judge1Store,
    judge2Store,
    judge3Store,
    judge4Store,
    judge5Store,
    mobileMenuStore,
    pageTitleStore
} from "./stores/stores";
import classNames from "classnames";
import Footer from "./components/footer/Footer";
import Team from "./pages/Team";
import FAQ from "./pages/FAQ";
import EventInfo from "./pages/EventInfo";
import History from "./pages/History";
import Ideas from "./pages/Ideas";
import HistoryDetail from "./pages/HistoryDetail";
import YourVoicePage from "./pages/YourVoice";
import ProjectShinkaPage from "./pages/ProjectShinka";


function App() {
    const isMobileMenuVisible = useStore(mobileMenuStore);
    const pageTitle = useStore(pageTitleStore);
    const judge1Unlocked = useStore(judge1Store);
    const judge2Unlocked = useStore(judge2Store);
    const judge3Unlocked = useStore(judge3Store);
    const judge4Unlocked = useStore(judge4Store);
    const judge5Unlocked = useStore(judge5Store);
    const location = useLocation()

    useEffect(() => {
        judge1Store.set(Boolean(judge1Unlocked));
        judge2Store.set(Boolean(judge2Unlocked));
        judge3Store.set(Boolean(judge3Unlocked));
        judge4Store.set(Boolean(judge4Unlocked));
        judge5Store.set(Boolean(judge5Unlocked));
    }, [judge1Unlocked,judge2Unlocked,judge3Unlocked,judge4Unlocked,judge5Unlocked]);

    useEffect(() => {
        document.title = `Coollab - ${pageTitle}`;
    }, [pageTitle]);

    return (
    <div className={classNames('app', {'scrollBlock': isMobileMenuVisible})}>
        <Header />

        {isMobileMenuVisible && <MobileMenu />}

            <Routes>
                <Route index element={<Home />} />
                <Route path='/program' element={<ProtectedRoute><EventInfo /></ProtectedRoute>} />
                <Route path='/faq' element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
                <Route path='/history' element={<ProtectedRoute><History /></ProtectedRoute>} />
                <Route path='/history/:slug' element={<ProtectedRoute><HistoryDetail /></ProtectedRoute>} />
                <Route path='/ideas' element={<ProtectedRoute><Ideas /></ProtectedRoute>} />
                <Route path='/team' element={<ProtectedRoute><Team /></ProtectedRoute>} />
                <Route path='/your-voice' element={<ProtectedRoute><YourVoicePage /></ProtectedRoute>} />
                <Route path='/project-shinka' element={<ProtectedRoute><ProjectShinkaPage /></ProtectedRoute>} />
            </Routes>

        {location.pathname !== '/' && <Footer />}
    </div>
    );
}

export default App;
